<template>
  <div class="home-page">
    <div class="home-page-cards-wrapper">
      <div class="home-page-cards-container">
        <div class="home-page-carrousel">
          <img src="/images/HomePageCover.png" class="home-page-carrousel-image" />
          <div class="home-page-carrousel-text">
            <div class="home-page-carrousel-header">
              The future of preventive eye care
            </div>
            <p class="home-page-carrousel-body">
              RETINAL IMAGING WITH ANALYSIS<br />
              TO PREVENT BLINDNESS CAUSED BY DIABETES,<br />
              AGE RELATED MACULAR DEGENERATION,<br />
              GLAUCOMA...
            </p>
          </div>
        </div>
        <div class="home-page-card is-3">
          <div class="home-page-card-header">
            <span class="home-page-card-header-text">If you are a</span>
            <span class="home-page-card-header-title">PATIENT</span>
          </div>
          <div class="home-page-card-button-container">
            <button class="home-page-card-button mr-2 cta-button" @click="handleButtonClicked('book-patient')">BOOK
              NOW</button>
            <button class="home-page-card-button" @click="handleButtonClicked('patient')">MORE INFO</button>
          </div>
        </div>
        <div class="home-page-card is-3">
          <div class="home-page-card-header">
            <span class="home-page-card-header-text">If you are a</span>
            <span class="home-page-card-header-title">PROFESSIONAL</span>
          </div>
          <div class="home-page-card-button-container">
            <div class="home-page-card-dropdown" :class="{ 'is-expanded': dropDownActive }">
              <input type="checkbox" name="" id="professional-checkbox" v-model="dropDownActive">
              <label class="home-page-card-dropdown-label" for="professional-checkbox">CHOOSE YOUR PROFESSION</label>
              <div class="os-icon icon-down" @click="toggleDropdown"></div>
              <div class="home-page-card-dropdown-list-wrapper">
                <ul class="home-page-card-dropdown-list os-scrollbar" ref="dropDownListRef">
                  <li class="home-page-card-dropdown-list-item">
                    <button @click="handleButtonClicked('ophthalmologist')"
                      class="home-page-card-dropdown-list-item-button">
                      Ophthalmologist
                    </button>
                  </li>
                  <li class="home-page-card-dropdown-list-item">
                    <button @click="handleButtonClicked('physician')" class="home-page-card-dropdown-list-item-button">
                      Physician
                    </button>
                  </li>
                  <li class="home-page-card-dropdown-list-item">
                    <button @click="handleButtonClicked('GP')" class="home-page-card-dropdown-list-item-button">
                      General Practitioner
                    </button>
                  </li>
                  <li class="home-page-card-dropdown-list-item">
                    <button @click="handleButtonClicked('optometrist')"
                      class="home-page-card-dropdown-list-item-button">
                      Optometrist
                    </button>
                  </li>
                  <li class="home-page-card-dropdown-list-item">
                    <button @click="handleButtonClicked('dietitian')" class="home-page-card-dropdown-list-item-button">
                      Dietitian
                    </button>
                  </li>
                  <li class="home-page-card-dropdown-list-item">
                    <button @click="handleButtonClicked('other')" class="home-page-card-dropdown-list-item-button">
                      Other
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="home-page-card is-3">
          <div class="home-page-card-header">
            <span class="home-page-card-header-text">If you are interested in</span>
            <span class="home-page-card-header-title">EMPLOYEE WELLNESS</span>
          </div>
          <div class="home-page-card-button-container">
            <button class="home-page-card-button" @click="handleButtonClicked('employee-wellness')">MORE INFO</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const dropDownActive = ref(false);
const dropDownListRef = ref(null);

const toggleDropdown = () => {
  dropDownActive.value = !dropDownActive.value;
}

watch(() => (dropDownActive.value), (newActive) => {
  if (newActive || newActive && newScroll) {
    let topOfDropDown = dropDownListRef.value.getBoundingClientRect().top;
    let endingPosition = window.innerHeight - 70;
    let maxHeight = endingPosition - topOfDropDown;
    if (dropDownListRef.value.scrollHeight > maxHeight) dropDownListRef.value.style.maxHeight = `${maxHeight}px`;
    else dropDownListRef.value.style.maxHeight = '';
  }
})

const handleButtonClicked = (clickEvent) => {
  switch (clickEvent) {
    case "patient":
      router.push("patient-more-info");
      break;
    case "specialist":
      router.push("specialist-more-info");
      break;
    case "referring-doctor":
      router.push("referring-doctor-more-info");
      break;
    case "optometrist":
      router.push("optometrist-more-info");
      break;
    case "employee-wellness":
      router.push("employee-wellness");
      break;
    case "ophthalmologist":
      router.push("ophthalmologist-page");
      break;
    case "GP":
      router.push("general-practitioner-page");
      break;
    case "physician":
      router.push("physician-page");
      break;
    case "dietitian":
      router.push("dietitian-page");
      break;
    case "book-patient":
      router.push("book-your-appointment");
      break;
    default:
      break;
  }
};
</script>